import React, { Component } from "react";

import SEO from "../components/layout/seo";
import SubHeader from "../components/layout/subheader";

import Space from "../components/space";

import Aos from 'aos';

import { Link } from "gatsby";




const onFocus = () =>
{
    //console.log('PaginaNaoEncontrada:','onFocus');
    Aos.refresh();
}

class PaginaNaoEncontrada extends Component
{
    render()
    {
        //console.log('PaginaNaoEncontrada: render');

        return (
            <>
                <SEO title="Página não encontrada" />
                <SubHeader pagename="Página não encontrada" bg="page-bg-nao-encontrado.jpg" />

                <div className="container">
                    <Space altura={50} />
                    <h1 style={{fontSize: 48}}>Desculpe...</h1>
                    <p style={{fontSize: 18}}>Você acabou de pegar uma rota que não existe.</p>
                    <Space altura={20} />
                    <p className="link"><Link to="/">Voltar para a página inicial</Link></p>
                    <Space altura={85} />
                </div>
            </>
        )
    }

    componentDidMount()
    {
        //console.log('PaginaNaoEncontrada: componentDidMount');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` ) {
            setTimeout(function() {
                //Aos.refresh();
                Aos.init();
            }, 500);
            window.addEventListener("focus", onFocus);
        }
    }

    componentDidUpdate()
    {
        //console.log('PaginaNaoEncontrada: componentDidUpdate');
    }

    componentWillUnmount()
    {
        //console.log('PaginaNaoEncontrada: componentWillUnmount');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` )
        {
            window.removeEventListener("focus", onFocus);
            //Aos.disable();
        }
    }
}

export default PaginaNaoEncontrada